<template>
  <v-container
    class="pa-2"
    style="overflowY: auto; height: 100%; width: 100%"
    fluid
  >
    <v-card
      v-if="expireAt"
      color="warn lighten-4"
      tile
      data-cy="occurrence-trash-message"
      class="d-flex flex-column align-center"
      elevation="0"
      min-height="68"
    >
      <v-card-title
        class="warn--text"
        style="word-break: auto-phrase;"
      >
        {{ expireMode }}
      </v-card-title>
      <v-card-text class="d-flex align-center justify-center">
        <v-icon
          class="mr-2"
          color="warn"
        >
          {{ icons.alert }}
        </v-icon>
        <span class="warn--text text-body-2 text-sm-body-1">
          {{
            $t(
              'deconve.occurrenceInTrashMessage',
              [$moment(expireAt).format('DD/MM/YYYY HH:mm:ss')]
            )
          }}h
        </span>
      </v-card-text>
    </v-card>

    <v-btn
      left
      absolute
      elevation="0"
      icon
      color="neutral"
      @click="goBack"
    >
      <v-icon>{{ icons.chevronLeft }}</v-icon>
    </v-btn>

    <v-form
      ref="form"
      v-model="valid"
      :class="$vuetify.breakpoint.md ? 'mt-8': 'mt-0'"
    >
      <v-container
        fluid
        style="max-width: 1200px"
      >
        <v-col dense>
          <v-row class="align-center mt-4">
            <v-col cols="9">
              <v-row class="d-flex align-center">
                <h2
                  v-if="occurrenceName"
                  class="pr-2 font-weight-medium textBlack--text"
                  data-dd-privacy="mask"
                >
                  {{ occurrenceName }}
                </h2>
                <v-skeleton-loader
                  v-else
                  style="flex: 1"
                  type="text"
                  max-width="50%"
                />
                <p
                  v-if="occurrenceCreatedAt"
                  class="mb-0 mt-2 caption font-weight-regular textBlack--text"
                  :class="{'pl-2': !isMobileVersion}"
                >
                  {{ occurrenceCreatedAt }}
                </p>
                <v-skeleton-loader
                  v-else
                  type="text"
                  class="ml-4"
                  style="flex: 1"
                  max-width="100px"
                />
              </v-row>
            </v-col>
            <v-col cols="3">
              <v-row class="d-flex flex-nowrap justify-end align-center">
                <square-button
                  icon-name="mdi-dots-horizontal"
                  icon-color="neutral"
                  data-cy="occurrence-settings"
                  color="transparentBackground"
                  outlined
                >
                  <v-list
                    dense
                    elevation="0"
                  >
                    <v-list-item
                      data-cy="occurrence-delete"
                      :disabled="!$can('delete', 'com.deconve.faceid.occurrence')"
                      @click="deleteOccurrenceDialog"
                    >
                      {{ $t('deconve.delete') }}
                    </v-list-item>
                    <v-list-item
                      v-if="expireAt"
                      data-cy="occurrence-restore-from-trash"
                      :disabled="!$can('update', 'com.deconve.faceid.occurrence')"
                      @click="restoreOccurrenceFromTrash"
                    >
                      {{ $t('deconve.restoreFromTrash') }}
                    </v-list-item>
                    <v-list-item
                      v-else
                      data-cy="occurrence-move-to-trash"
                      :disabled="!$can('update', 'com.deconve.faceid.occurrence')"
                      @click="moveOccurrenceToTrash"
                    >
                      {{ $t('deconve.moveToTrash') }}
                    </v-list-item>
                  </v-list>
                </square-button>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="align-center mt-4">
            <div
              v-if="unitName"
              class="subtitle-1 font-weight-regular textBlack--text"
              data-dd-privacy="mask"
            >
              {{ unitName }}
            </div>
            <v-skeleton-loader
              v-else
              type="text"
              style="flex: 1"
              max-width="100px"
            />
          </v-row>
          <v-row
            v-if="tags"
            class="align-center mt-4"
          >
            <div
              v-if="tags.length > 0"
              class="d-flex flex-wrap mt-2"
            >
              <tag
                v-for="tag in tags"
                :key="tag"
                class="pr-2"
                :tag-id="tag"
              />
            </div>
          </v-row>
          <div style="height: 64px" />
          <v-row class="d-flex">
            <v-col cols="6">
              <v-row class="align-center mt-2">
                <div class="subtitle-1 font-weight-medium textBlack--text">
                  {{ $t('deconve.occurrenceStatus') }}
                </div>
              </v-row>
              <v-row class="align-center mt-4">
                <div
                  v-if="occurrenceStatus"
                  class="mb-4 subtitle-2 font-weight-regular textBlack--text"
                >
                  {{ occurrenceStatus }}
                </div>
                <v-skeleton-loader
                  v-else
                  style="flex: 1"
                  type="text"
                  max-width="50%"
                />
              </v-row>
              <v-row class="align-center mt-6">
                <div class="subtitle-1 font-weight-medium textBlack--text">
                  {{ $t('deconve.occurrenceValueOfPrevention') }}
                </div>
              </v-row>
              <v-row class="align-center mt-4">
                <div
                  v-if="occurrenceValueOfPrevention !== undefined"
                  class="mb-4 subtitle-2 font-weight-regular textBlack--text"
                >
                  {{ $t('deconve.occurrenceValueOfPreventionInSign',
                        [occurrenceValueOfPrevention]) }}
                </div>
                <v-skeleton-loader
                  v-else
                  style="flex: 1"
                  type="text"
                  max-width="50%"
                />
              </v-row>
              <v-row class="align-center mt-6">
                <div class="subtitle-1 font-weight-medium textBlack--text">
                  {{ $t('deconve.occurrenceValueOfLoss') }}
                </div>
              </v-row>
              <v-row class="align-center mt-4">
                <div
                  v-if="occurrenceValueOfLoss !== undefined"
                  class="mb-4 subtitle-2 font-weight-regular warn--text"
                >
                  {{ $t('deconve.occurrenceValueOfLossInSign',
                        [occurrenceValueOfLoss]) }}
                </div>
                <v-skeleton-loader
                  v-else
                  style="flex: 1"
                  type="text"
                  max-width="50%"
                />
              </v-row>

              <v-row class="align-center mt-6">
                <v-checkbox
                  v-if="occurrenceCopsWereCalled !== undefined"
                  v-model="occurrenceCopsWereCalled"
                  readonly
                  class="disablePointer pa-0 ma-0"
                  :label="$t('deconve.policeWereCalled')"
                />
                <v-skeleton-loader
                  v-else
                  style="flex: 1"
                  type="text"
                  max-width="50%"
                />
              </v-row>
              <v-row class="align-center mt-4">
                <v-checkbox
                  v-if="occurrenceThereWasAnArrest !== undefined"
                  v-model="occurrenceThereWasAnArrest"
                  readonly
                  class="disablePointer pa-0 ma-0"
                  :label="$t('deconve.thereWereAnArrest')"
                />
                <v-skeleton-loader
                  v-else
                  style="flex: 1"
                  type="text"
                  max-width="50%"
                />
              </v-row>
              <v-row class="align-center mt-4 mb-2">
                <div class="subtitle-1 font-weight-medium textBlack--text">
                  {{ $t('deconve.occurrenceFiles') }}
                </div>
              </v-row>
              <v-row
                v-if="files"
                class="align-center mt-4 mb-1"
              >
                <div
                  v-if="files.length > 0"
                  class="d-flex flex-wrap mt-2"
                >
                  <file-item
                    v-for="file in files"
                    :key="file.name"
                    :name="file.name"
                    class="pa-1"
                    @open="download(file.url)"
                  />
                </div>
              </v-row>
              <v-row
                v-else
                class="mb-2 subtitle-2 font-weight-regular textBlack--text"
              >
                {{ $t('deconve.noOccurrenceFiles') }}
              </v-row>
            </v-col>
          </v-row>
          <v-row class="align-center mt-6">
            <div class="subtitle-1 font-weight-medium textBlack--text">
              {{ $t('deconve.about') }}
            </div>
          </v-row>
          <v-row class="align-center mt-4">
            <v-textarea
              v-model="occurrenceAbout"
              :counter="maxAboutTextLength"
              :rules="aboutRules"
              class="disablePointer body-2 text-sm-body-1 mb-6"
              height="100"
              no-resize
              outlined
            />
          </v-row>
          <span
            class="mt-4 unreviewed"
            style="display: block; width: 100%; border-top: 1px solid"
          />
          <v-row class="align-center mt-7 mb-2">
            <div class="subtitle-1 font-weight-medium textBlack--text">
              {{ $t('deconve.occurrenceNotifications') }}
            </div>
          </v-row>
          <v-row
            v-if="notificationsPreview.length > 0"
            class="align-center mt-4"
          >
            <v-col
              v-for="notification in notificationsPreview"
              :key="notification.id"
              class="pa-1"
              style="height: fit-content"
              cols="12"
              sm="6"
              md="4"
              lg="3"
            >
              <notification-preview
                :id="notification.id"
                :key="notification.id"
                :data="notification"
              />
            </v-col>
          </v-row>
          <v-row
            v-else
            class="mb-2 subtitle-2 font-weight-regular textBlack--text"
          >
            {{ $t('deconve.noOccurrenceNotifications') }}
          </v-row>
          <v-row class="align-center mt-7 mb-2">
            <div class="subtitle-1 font-weight-medium textBlack--text">
              {{ $t('deconve.occurrencePeople') }}
            </div>
          </v-row>
          <v-row
            v-if="personPreview.length > 0"
            class="align-center mt-4"
          >
            <v-col
              v-for="person in personPreview"
              :key="person.id"
              class="pa-1"
              style="height: fit-content"
              cols="12"
              sm="6"
              md="4"
              lg="3"
            >
              <person-preview :id="person.id" />
            </v-col>
          </v-row>
          <v-row
            v-else
            class="mb-2 subtitle-2 font-weight-regular textBlack--text"
          >
            {{ $t('deconve.noOccurrencePeople') }}
          </v-row>
          <v-row class="align-center mt-6">
            <v-checkbox
              v-if="isOccurrenceFinished !== undefined"
              v-model="isOccurrenceFinished"
              readonly
              class="disablePointer pa-0 ma-0"
              :label="$t('deconve.occurrenceFinished')"
            />
            <v-skeleton-loader
              v-else
              style="flex: 1"
              type="text"
              max-width="50%"
            />
          </v-row>
        </v-col>
      </v-container>
    </v-form>
    <v-btn
      :disabled="occurrence === undefined"
      fab
      color="primary"
      fixed
      bottom
      right
      @click="$emit('onEditButtonClicked')"
    >
      <v-icon>{{ icons.pencilOutline }}</v-icon>
    </v-btn>
    <confirmation-dialog ref="confirm" />
    <div v-if="alert && alertMessageText">
      <v-snackbar
        v-model="alert"
        data-cy="snackbar"
        :color="getAlertColor"
      >
        {{ alertMessageText }}

        <template v-slot:action="{ attrs }">
          <v-btn
            text
            v-bind="attrs"
            @click="resetAlert"
          >
            <v-icon color="white">
              {{ icons.close }}
            </v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </div>
  </v-container>
</template>

<script>
// Copyright (C) 2024 Deconve Technology. All rights reserved.

import { mapGetters, mapActions } from 'vuex';
import SquareButton from '@/components/SquareButton.vue';
import NotificationPreview from '@/components/NotificationPreview.vue';
import {
  mdiChevronLeft, mdiPencilOutline, mdiClose, mdiAlertCircleOutline,
} from '@mdi/js';
import { OCCURRENCE_THEFT_STATUS } from '@/utils/occurrence';
import Tag from '@/components/Tag.vue';
import ConfirmationDialog from '@/components/ConfirmationDialog.vue';
import FileItem from '@/components/FileItem.vue';
import PersonPreview from '../People/PersonPreview.vue';

export default {
  name: 'OccurrenceViewer',
  components: {
    SquareButton,
    PersonPreview,
    Tag,
    NotificationPreview,
    ConfirmationDialog,
    FileItem,
  },
  props: {
    occurrenceId: { type: String, required: true },
  },
  data() {
    return {
      fromRoute: null,
      valid: true,
      alert: false,
      alertMessage: '',
      alertColor: '',
      maxAboutTextLength: 600,
      notificationsPreview: [],
      personPreview: [],
      tags: [],
      files: [],
      isEditingNotification: false,
      occurrence: undefined,
      notificationName: '',
      unitName: '',
      unitId: '',
      icons: {
        chevronLeft: mdiChevronLeft,
        pencilOutline: mdiPencilOutline,
        close: mdiClose,
        alert: mdiAlertCircleOutline,
      },
    };
  },
  computed: {
    ...mapGetters({
      getOccurrence: 'faceid/getOccurrence',
      getFileUrl: 'faceid/getFileUrl',
    }),
    alertMessageText() {
      return this.alertMessage;
    },
    getAlertColor() {
      return this.alertColor;
    },
    occurrenceRef() {
      return this.getOccurrence(this.occurrenceId);
    },
    occurrenceName() {
      return this.occurrence?.name || this.notificationName || this.$t('deconve.occurrence');
    },
    occurrenceCreatedAt() {
      if (this.occurrence) {
        return this.$moment(this.occurrence.created_at).format('DD/MM/YYYY HH:mm:ss');
      }

      return '';
    },
    occurrenceStatusInfo() {
      return this.occurrence?.theft_status;
    },
    occurrenceStatus() {
      const occurrenceStatus = this.occurrenceStatusInfo;

      switch (occurrenceStatus) {
        case OCCURRENCE_THEFT_STATUS.stole:
          return this.$t('deconve.stole');
        case OCCURRENCE_THEFT_STATUS.triedToSteal:
          return this.$t('deconve.triedToStealButWasStopped');
        case OCCURRENCE_THEFT_STATUS.didNotTryToSteal:
          return this.$t('deconve.withoutAlteration');
        case null:
          return this.$t('deconve.noOccurrenceStatus');
        default:
          return undefined;
      }
    },
    occurrenceValueOfLoss() {
      return this.occurrence?.value_of_loss;
    },
    occurrenceValueOfPrevention() {
      return this.occurrence?.value_of_prevention;
    },
    occurrenceThereWasAnArrest() {
      return this.occurrence?.there_was_an_arrest;
    },
    occurrenceCopsWereCalled() {
      return this.occurrence?.cops_were_called;
    },
    isOccurrenceFinished() {
      return this.occurrence?.is_finished;
    },
    occurrenceAbout() {
      return this.occurrence?.about;
    },
    canUpdateOccurrence() {
      return !this.$can('update', 'com.deconve.faceid.occurrence');
    },
    aboutRules() {
      return [
        (v) => (!v || (v && v.length <= this.maxAboutTextLength))
          || this.$t('deconve.inputRules.about', [this.maxAboutTextLength]),
      ];
    },
    isMobileVersion() {
      return this.$vuetify.breakpoint.mobile;
    },
    avatarSize() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '140px';
        case 'sm': return '170px';
        case 'md': return '190px';
        case 'lg': return '200px';
        case 'xl': return '200px';
        default: return '140px';
      }
    },
    expireAt() {
      return this.occurrence?.expire_at || '';
    },
    expireMode() {
      switch (this.occurrence?.expire_mode) {
        case 'deleted by user':
          return this.$t('deconve.notification.expireMode.deletedByUser');
        case 'notification deleted':
          return this.$t('deconve.notification.expireMode.personDeleted');
        default:
          return '';
      }
    },
  },
  watch: {
    occurrenceRef() {
      this.occurrence = this.occurrenceRef;
    },
  },
  created() {
    this.onOccurrenceIdChanged();
  },
  methods: {
    ...mapActions({
      fetchOccurrence: 'faceid/fetchOccurrence',
      fetchUnit: 'units/fetchUnit',
      fetchNotification: 'faceid/fetchNotification',
      deleteOccurrence: 'faceid/deleteOccurrence',
      patchOccurrence: 'faceid/patchOccurrence',
    }),
    resetAlert() {
      this.alert = false;
      this.alertColor = '';
      this.alertMessage = '';
    },
    showAlert(color, message) {
      this.alertColor = color;
      this.alertMessage = message;
      this.alert = true;
      this.isUpdatingOccurrence = false;
    },
    goBack() {
      this.$emit('onGoBackButtonClicked');
    },
    onOccurrenceIdChanged() {
      // To force update when the route changes
      this.occurrence = undefined;
      this.notificationsPreview = [];
      this.personPreview = [];
      this.tags = [];
      this.files = [];
      this.unitName = '';
      this.unitId = '';

      this.fetchOccurrence(this.occurrenceId).then((occurrenceData) => {
        if (occurrenceData) {
          this.occurrence = occurrenceData;
          const {
            notifications, unit, people, tags, files,
          } = occurrenceData;

          this.personPreview = people;

          this.tags = tags;
          this.files = files;

          notifications.forEach(({ id: notificationId }) => {
            this.fetchNotification(notificationId).then((notificationData) => {
              const { person, video } = notificationData;

              this.notificationName = video.name;

              this.notificationsPreview.push({
                id: notificationId,
                video: { id: video.id },
                person: { id: person.id },
              });
            });
          });

          if (unit) {
            const { id: unitId } = unit;

            this.unitId = unitId;

            this.fetchUnit(unitId).then((unitData) => {
              const { name: unitName } = unitData;

              this.unitName = unitName;
            });
          } else {
            this.unitName = this.$t('deconve.unknownUnit');
          }
        }
      });
    },
    deleteOccurrenceDialog() {
      this.$refs.confirm
        .open(
          this.$t('deconve.deleteOccurrenceTitle'),
          this.$t('deconve.deleteOccurrenceMessage'),
        )
        .then((confirm) => {
          if (confirm) {
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            this.deleteOccurrence(this.occurrenceId).then(() => this.goBack()).catch(() => { });
          }
        });
    },
    moveOccurrenceToTrash() {
      this.editOccurrence({
        occurrenceId: this.occurrenceId,
        // eslint-disable-next-line @typescript-eslint/camelcase
        updateOccurrenceParams: { move_to_trash: true },
      });
    },
    restoreOccurrenceFromTrash() {
      this.editOccurrence({
        occurrenceId: this.occurrenceId,
        // eslint-disable-next-line @typescript-eslint/camelcase
        updateOccurrenceParams: { restore_from_trash: true },
      });
    },
    editOccurrence(params) {
      this.isEditingNotification = true;
      this.patchOccurrence(params).then(() => {
        this.isEditingNotification = false;
        this.onOccurrenceIdChanged();
        this.showAlert('primary', this.$t('deconve.editOccurrenceSuccess'));
      }).catch(() => {
        this.isEditingNotification = false;
        this.showAlert('warn', this.$t('deconve.editOccurrenceError'));
      });
    },
    download(fileUrl) {
      if (fileUrl) {
        window.open(fileUrl);
      } else {
        this.enableAlert = true;
      }
    },
  },
};
</script>

<style scoped>
textarea:focus {
  outline: 0;
}

.disablePointer {
  pointer-events: none !important;
}
</style>
